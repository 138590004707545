<template>
  <div class="main-content" v-if="loading.success">
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Open+Sans&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet" />

    <div class="container">
      <div class="row" style="padding-top: 50px; padding-bottom: 30px">
        <div class="col span_12">
          <div class="vc_column-inner">
            <div class="wpb_wrapper">
              <div class="wpb_text_column wpb_content_element" style="padding-bottom: 24px">
                <div class="wpb_wrapper">
                  <h2>Generate A Secure Factfind For A Prospect</h2>
                </div>
              </div>
              <div role="form" lang="en-US" dir="ltr">
                <form @submit.prevent="handleSubmit" novalidate>
                  <p>
                    Broker Email:<br />
                    <span>
                      <input
                        type="text"
                        name="broker"
                        size="40"
                        v-model="brokerEmail"
                        aria-required="true"
                        :aria-invalid="!emailValid"
                        placeholder="Your Contact Details"
                      />
                      <span v-if="isSubmitted && !emailValid" class="invalid-warning">
                        Please enter a valid email address
                      </span>
                    </span>
                  </p>
                  <p>
                    Proposal Form:<br />
                    <span>
                      <multiselect
                        v-model="selectedTemplate"
                        :options="templateOptions"
                        track-by="id"
                        label="name"
                        :allow-empty="false"
                        deselect-label="Can't remove this value"
                        placeholder="Pick a factfinder"
                      >
                      </multiselect>
                    </span>
                  </p>
                  <p>
                    Client Name:<br />
                    <span>
                      <input
                        type="text"
                        name="client"
                        size="40"
                        v-model="clientName"
                        aria-required="true"
                        :aria-invalid="!clientNameValid"
                        placeholder="Name of Proposed Company"
                      />
                    </span>

                    <span v-if="isSubmitted && !clientNameValid" class="invalid-warning">
                      Please fill in this field
                    </span>
                  </p>

                  <p style="text-align: center; padding-top: 50px">
                    <button type="Submit" class="nectar-button large accent-color regular-button">
                      <span>Generate Unique Link</span>
                    </button>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" v-else>
    <PageLoading loadingText="Loading. Please wait..." v-if="loading.loading"></PageLoading>
    <Page404 v-else :longErrorMessage="loading.error" errorText="" errorCode="Error"></Page404>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect
  },
  props: {
    tenant: String
  },
  data() {
    return {
      clientName: '',
      brokerEmail: '',
      isSubmitted: false,
      templateOptions:[],
      selectedTemplate: null,
      loading: { success: false, loading: true, error: ""}
    };
  },
  computed: {
    clientNameValid() {
      return this.clientName.length > 2;
    },
    emailValid() {
      var regEx = /(.*)@(.*)/;
      return regEx.test(this.brokerEmail);
    }
  },
  beforeMount() {
    this.getProposalTemplates();
  },
  methods: {
    handleSubmit() {
      if (this.$debug) console.log('submit');
      this.isSubmitted = true;

      if (this.clientNameValid && this.emailValid) {
        let templateId = this.selectedTemplate.id;
        this.$api.wholesale.postGenerateNewFormLink(this.tenant, templateId, this.clientName, this.brokerEmail).then(res => {
          if (this.$debug) console.log(res.data);
          this.$router.push(`/r/${this.tenant}/${templateId}/result?r=${res.data}`);
        });
      }
    },
    async getProposalTemplates() {
      if (this.$debug) console.log('getProposalTemplates', this.tenant);
      this.loading = { success: false, loading: true, error: ""}

      try {
        const res = await this.$api.wholesale.getProposalTemplates(this.tenant)
        const templates = await res.data;
        if(!templates.length || templates.length === 0){
          throw "No Factfinders are enabled. Please contact your broker to enable factfinders for wholesale use."
        }

        this.templateOptions = templates;
        this.selectedTemplate = templates[0];

        this.loading.success = true;
        this.loading.loading = false;
      } catch (error) {
        this.loading.loading = false;
        this.loading.error = error;
      }
    },
  }
};
</script>

<style scoped>
.main-content {
  background-color: #f8f8f8;
}

#page-header-bg h2,
body h2,
article.post .post-header h2,
article.post.quote .post-content h2,
article.post.link .post-content h2,
article.post.format-status .post-content h2,
#call-to-action span,
.woocommerce .full-width-tabs #reviews h3,
.row .col.section-title h2,
.nectar_single_testimonial[data-style='bold'] p {
  font-family: 'Montserrat';
  letter-spacing: 0.5px;
  font-size: 28px;
  line-height: 28px;
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #284546;
  letter-spacing: 0px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  font-family: 'Open Sans';
  font-weight: 600;
}

p {
  font-family: 'Open Sans';
  letter-spacing: 0.5px;
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
  color: #284546;
}

input[type='text'],
textarea,
input[type='email'],
input[type='password'],
input[type='tel'],
input[type='url'],
input[type='search'],
input[type='date'] {
  padding: 10px;
  width: 100%;
  font-size: 12px;
  font-family: inherit;
  line-height: 22px;
  color: #555;
  background-color: #efefef;
  border: none;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  box-sizing: border-box;
}

dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend {
  vertical-align: baseline;
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  outline: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.nectar-button {
  border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  -o-border-radius: 5px 5px 5px 5px;
  box-shadow: 0 -3px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 -3px rgba(0, 0, 0, 0.1) inset;
  -webkit-box-shadow: 0 -3px rgba(0, 0, 0, 0.1) inset;
  -o-box-shadow: 0 -3px rgba(0, 0, 0, 0.1) inset;
  transition: opacity 0.45s cubic-bezier(0.25, 1, 0.33, 1);
  -moz-transition: opacity 0.45s cubic-bezier(0.25, 1, 0.33, 1);
  -webkit-transition: opacity 0.45s cubic-bezier(0.25, 1, 0.33, 1);
  -o-transition: opacity 0.45s cubic-bezier(0.25, 1, 0.33, 1);
  color: #fff;
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 2px;
  padding: 13px 18px;
  position: relative;
  text-transform: uppercase;
  top: -3px;
  margin-bottom: 10px;
  opacity: 1;
  line-height: 20px;
  background-color: #284546 !important;
  display: inline-block;
  font-family: 'Montserrat';
}
.nectar-button.large {
  font-size: 14px;
  padding: 15px 22px;
  box-shadow: 0 -3px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 -3px rgba(0, 0, 0, 0.1) inset;
  -webkit-box-shadow: 0 -3px rgba(0, 0, 0, 0.1) inset;
  -o-box-shadow: 0 -3px rgba(0, 0, 0, 0.1) inset;
  width: 100%;
}

input:focus {
  border: none;
  border-color: transparent;

  outline: none;
}

.invalid-warning {
  background-color: rgb(255, 255, 255);
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
}
</style>
