import Vue from 'vue'
import Router from 'vue-router'
import { authGuard } from '@shared/services/auth/authGuard.js';

// CLient area
import ClientProposalFillPage from '@survey/fill/pages/ClientProposalFillPage.vue'
import ClientLandingPage from '@survey/fill/pages/ClientLandingPage.vue'
import ClientCompletedPage from '@survey/fill/pages/ClientCompletedPage.vue'
import RestrictedProposalFillPage from '@survey/fillRestrictedProposal/pages/RestrictedProposalFillPage.vue'
import RestrictedCompletedPage from '@survey/fillRestrictedProposal/pages/RestrictedCompletedPage.vue'
import RestrictProposalAccessPage from '@survey/clientAdmin/pages/RestrictProposalAccessPage.vue'


// System
import Page404 from '@shared/components/coreUi/Page404.vue'


// Wholesale
import GenerateLinkPage from '@survey/wholesale/pages/GenerateLinkPage.vue'
import GeneratedLinkResultPage from '@survey/wholesale/pages/GeneratedLinkResultPage.vue'

Vue.use(Router)
 
export default new Router({
  mode: 'history',
  routes: [
    // Catch all
    {
      path: '*',
      component: Page404
    },

    // Clients
    {
      path: '/landing/:proposalUniqueLink',
      component: ClientLandingPage,
      props: true
    },
    {
      path: '/form/:proposalUniqueLink',
      component: ClientProposalFillPage,
      props: true
    },
    {
      path: '/completed/:proposalUniqueLink',
      component: ClientCompletedPage,
      props: true
    },

    // Restricted Pages
    {
      path: '/restricted/proposal/:proposalUniqueLink',
      component: RestrictedProposalFillPage,
      props: true,
      beforeEnter: authGuard
    },
    {
      path: '/restricted/completed/:proposalUniqueLink',
      component: RestrictedCompletedPage,
      props: true,
      beforeEnter: authGuard
    },
    {
      path: '/restrict-access/:proposalUniqueLink',
      component: RestrictProposalAccessPage,
      props: true,
      beforeEnter: authGuard
    },


    // Wholesale
    {
      path: '/r/:tenant/wholesale',
      component: GenerateLinkPage,
      props: ({ params }) => ({ tenant: params.tenant })
    },
    {
      path: '/r/:tenant/:templateId/result',
      component: GeneratedLinkResultPage,
      props: route => ({ templateId: parseInt(route.params.templateId), tenant: route.params.tenant, link: route.query.r })
    }
  ]
});
