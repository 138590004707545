const debug = process.env.NODE_ENV !== 'production';

function initialState(){
  return {
    proposalUniqueLink: '',
    skipLoad: false,
    isLoading: false,
    error: null,
  }
}

const state = initialState();
export const getters = {
  isLoadingOrError(state, getters) {
    if(state.isLoading)
      return true;

    if(getters.metaResponseStatus !== 200){ 
      return true;
    }

    return false;
  },
  metaResponseStatus(state, getters, rootState){
    return rootState.meta.loadingState.metaRespStatus;
  },
};

const mutations ={
  resetState(state){
    if (debug) console.log('resetState');
    Object.assign(state, initialState())
  },
  skipMetaReq(state){
    if (debug) console.log('skipMetaReq');
    state.skipLoad = true;
  },
  clearSkipMetaReq(state){
    if (debug) console.log('clearSkipMetaReq');

    state.skipLoad = false;
  },
  loadingMeta(state, payload){
    if (debug) console.log('loadingMeta triggered with', payload);
    
    state.isLoading = true;
    state.proposalUniqueLink = payload.proposalUniqueLink;
  },
  metaError(state, error){
    if (debug) console.log('metaError triggered with', error);
    state.error = error;
  },

  // This is set by component when it's finished doing all it's routing and additional loading
  loadingComplete(state){
    if (debug) console.log('loadingComplete triggered');
    state.isLoading = false;
  }
};


const actions = {
  /*
    payload = { proposalUniqueLink, restrictedSource}
  */
  async LOAD_META({commit, state, dispatch}, payload) {
    payload.disableGlobalErrHandler = true;

    if (debug) console.log(payload);
    
    try{
      if(state.skipLoad === true && payload.proposalUniqueLink === state.proposalUniqueLink){
        if (debug) console.log('Skipping loading')
        commit('clearSkipMetaReq')
      }else{
        commit('resetState');
        commit('loadingMeta', payload)
  
        await dispatch('meta/FETCH_META', payload, { root: true }); 
      }
    } catch (error) {
      // anything but 200
      commit('metaError', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};