<template>
  <div>
    <ProposalStateTransitionEngine :proposalUniqueLink="proposalUniqueLink"></ProposalStateTransitionEngine>
    <div v-if="!isLoadingOrError" >
      <VCompletedProposal 
        :proposalUniqueLink='proposalUniqueLink' :isRestrictedPage="false"
        @reopen="onReopen">
      </VCompletedProposal>
    </div> 
  </div>
</template>

<script>
import ProposalStateTransitionEngine from '@survey/fill/ui/ProposalStateTransitionEngine.vue';
import VCompletedProposal from '@survey/fill/ui/VCompletedProposal.vue';

import reopenProposalDialog from '@survey/fill/ui/reopenProposalDialog.js';
import { mapGetters } from 'vuex';

export default {
  components: {
    ProposalStateTransitionEngine,
    VCompletedProposal,
  },
  props: ['proposalUniqueLink'],
  computed: {
    ...mapGetters('proposalLoad', ['isLoadingOrError']),
  },
  methods: {
    onReopen() {
      const fn = reopenProposalDialog.bind(this);
      fn(this.proposalUniqueLink, false,`/landing/${this.proposalUniqueLink}`);
    },
  },
};
</script>

<style>

</style>
