<template>
  <div>
    <AdminNavbar></AdminNavbar>
    <div class="lay-nav-wrapper">
      <QuestionsStepperSidebar
        :bookmarks="tableOfContents"
        :focusedIds="focus"
        :active="!formExists"
        :autosaving="saving"
        :offline="offline"
        :proposalUniqueLink="proposalUniqueLink"
        :proposalAccessList="proposalAccessList"
        :isRestricted="restricted"
        @restrictAccess="onUnRestrictAccess"
      ></QuestionsStepperSidebar>
      <ProposalStateTransitionEngine :proposalUniqueLink="proposalUniqueLink" @loaded="onLoaded"></ProposalStateTransitionEngine>
      <div v-if="!isLoadingOrError"  class="container">
        <h1 class="mt-5">{{ displayTitle }}</h1>
        <div>
          <div v-for="(content, index) in filteredSections" :key="content.id">
            <QuestionSection v-bind="content" :index="index" :totalSections="filteredSections.length"></QuestionSection>
            <hr />
          </div>
        </div>
        <div class="lay-options-both mt-5 mb-5">
          <VEditButton class="eb-success btn-block attach-shepherd-submit" @click="onSubmit" :disabled="!submitButtonEnabled"
            >Submit</VEditButton
          >
        </div>
      </div>
      <v-dialog />
      <VFormOffline />
    </div>
  </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex';

import ProposalStateTransitionEngine from '@survey/fill/ui/ProposalStateTransitionEngine.vue';
import QuestionSection from '@shared/components/questions/ui/QuestionSection.vue';
import QuestionsStepperSidebar from '@shared/components/stepper/QuestionsStepperSidebar.vue';
import VFormOffline from '@shared/components/formFilling/VFormOffline.vue';
import AdminNavbar from '@survey/navbar/AdminNavbar.vue';

import { submitProposalDialog } from '@survey/fill/ui/submitProposalDialog.js';

export default {
  components: {
    QuestionSection,
    QuestionsStepperSidebar,
    VFormOffline,
    ProposalStateTransitionEngine,
    AdminNavbar
  },
  name: 'RestrictedProposalFillPage',
  data() {
    return {
      submitButtonEnabled: true,
      internalIsLoading: true,
      proposalAccessList: [],
    };
  },
  props: ['proposalUniqueLink'],
  computed: {
    ...mapState('fill', ['form', 'focus', 'tableOfContents', 'formExists', 'saving', 'offline']),
    ...mapState('meta', ['displayTitle', 'restricted']),
    ...mapGetters('fill', ['filteredSections']),
    ...mapGetters('proposalLoad', ['isLoadingOrError']),
  },
  beforeDestroy() {
    this.unregisterForAnswerUpdates();
  },
  methods: {
    onLoaded(){
      if (this.$debug) console.log('Loading form: ', this.proposalUniqueLink);

      this.registerForAnswerUpdates();
    },
    registerForAnswerUpdates() {
      this.$proposalHub.proposalOpened(this.proposalUniqueLink);
      this.$proposalHub.$on('answer-changed', this.onAnswerChanged);
      this.$proposalHub.$on('subscribe-list', this.onSubscribeListChange);
    },
    unregisterForAnswerUpdates() {
      if (this.$debug) console.log('Unsubscribe Signalr');
      this.$proposalHub.$off('answer-changed', this.onAnswerChanged);
      this.$proposalHub.$off('subscribe-list', this.onSubscribeListChange);
      this.$proposalHub.proposalClosed(this.proposalUniqueLink);
    },
    onSubmit() {
      this.submitButtonEnabled = false;

      const vue = this;
      const onSubmitFn = () => { // After 2 seconds redirect to completed page
        setTimeout(function () { 
          vue.$router.push(`/restricted/completed/${vue.proposalUniqueLink}`);
        }, 2000)
      };
      const onCancelFn = () => {              
        vue.submitButtonEnabled = true;
      }

      submitProposalDialog(this.$modal, this.$toast, this.$store, onSubmitFn, onCancelFn);
    },
    onAnswerChanged(data) {
      if (this.$debug) console.log('onAnswerChanged', data);
      this.$store.commit('fill/setAnswer', { id: data.id, value: data.value });
    },
    onSubscribeListChange(data) {
      if (this.$debug) console.log('onSubscribeListChange', data);

      this.proposalAccessList = data.ipAddresses;
    },
    onUnRestrictAccess(){
      this.$router.push(`/restrict-access/${this.proposalUniqueLink}`);
    }
  },
};
</script>

<style scoped>
.eb-large {
  width: 150px;
}
</style>
