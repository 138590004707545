<template>
  <div>
    <p class="col-sm-12" style="padding: 1%; margin-bottom: 2%; font-size: 18px">
      Tell us about your experience and help us improve!
    </p>
    <textarea
      v-model="feedbackName"
      placeholder="Name (Optional)"
      class="col-sm-12 form-control form-control-lg"
      style="min-height: 2rem; max-height: 3rem; font-size: 16px; margin-bottom: 3%"
      type="text"
    ></textarea>
    <textarea
      v-model="feedbackEmail"
      placeholder="Email Address (Optional)"
      class="col-sm-12 form-control form-control-lg"
      style="min-height: 2rem; max-height: 3rem; font-size: 16px; margin-bottom: 3%"
      type="text"
    ></textarea>
    <textarea
      v-model="feedbackInput"
      rows="5"
      class="col-sm-12 form-control form-control-lg"
      type="text"
      :placeholder="feedbackFieldPlaceholder"
      style="min-height: 10rem; max-height: 50rem; font-size: 16px"
    ></textarea>
    <button
      @click="checkFeedbackInputFields(feedbackName, feedbackEmail, feedbackInput)"
      type="submit"
      class="col-sm-12 btn btn-success mb-2"
      style="margin: 2% 0"
    >
      Submit Feedback
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      feedbackName: '',
      feedbackEmail: '',
      feedbackInput: '',
      feedbackFieldPlaceholder: 'Feedback...',
    };
  },
  methods: {
    checkFeedbackInputFields(feedbackName, feedbackEmail, feedbackInput) {
      if (feedbackName.length === 0) {
        feedbackName = "No 'Name' submitted";
      }
      if (feedbackEmail.length === 0) {
        feedbackEmail = "No 'Email' submitted";
      }
      if (feedbackInput.length === 0 || feedbackInput === 'Please fill in the feedback field before submitting') {
        this.feedbackFieldPlaceholder = 'Please fill in the feedback field before submitting';
      }
      else{
        this.submitFeedback(feedbackName, feedbackEmail, feedbackInput);
        this.feedbackFieldPlaceholder = 'Thank you for your feedback!';
        this.resetFeedbackInputs();
      }
    },
    resetFeedbackInputs() {
      this.feedbackName = '';
      this.feedbackEmail = '';
      this.feedbackInput = '';
    },
    submitFeedback(feedbackName, feedbackEmail, feedbackInput) {
      let payload = {
        feedback: feedbackInput,
        emailAddress: feedbackEmail,
        name: feedbackName,
      };
      this.$api.utils.postSubmitFeedback(payload);
      this.$toast('Thanks for the feedback!');
      this.$emit('completeFeedback')
    },
  },
};
</script>

<style>
</style>