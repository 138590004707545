<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">You will unrestrict access to the factfinder</h5>
      </div>
      <div class="modal-body">
        <p>Anyone with the link will be able to access the factfinder.</p>
      </div>
      <div class="mt-3 lay-card-bottom modal-footer">
        <VEditButton class="b-100 eb-sec" @click="$emit('cancel')">Cancel</VEditButton>
        <VEditButton class="b-100 eb-prim"  @click="$emit('ok')">Ok</VEditButton>
      </div>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style scoped>

</style>