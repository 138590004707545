import { HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr';

const debug = process.env.NODE_ENV !== 'production';

export default {
  install(Vue) {
    let apiPath = `/hub/proposal`;
    if (Vue.prototype.$http.defaults.baseURL) apiPath = `${Vue.prototype.$http.defaults.baseURL}hub/proposal`;

    if (debug) console.log('Hub api Path', apiPath);

    const connection = new HubConnectionBuilder()
      .withUrl(apiPath) // get url
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();

    async function start() {
      try {
        await connection.start();
        //safe to log
        console.log('SignalR Connected.');
      } catch (err) {
        if (debug) console.log(err);
        setTimeout(start, 5000);
      }
    }

    connection.onclose((error) => {
      //safe to log
      console.log(`Connection closed due to error "${error}". Try refreshing this page to restart the connection.`);
    });

    connection.onreconnecting((error) => {
      //safe to log
      console.assert(connection.state === HubConnectionState.Reconnecting);
      console.log(`Connection lost due to error "${error}". Reconnecting.`);
    });

    // See for reasoning: https://www.dotnetcurry.com/aspnet-core/1480/aspnet-core-vuejs-signalr-app
    // Set up vue event bus
    const proposalHub = new Vue();

    proposalHub.proposalOpened = (proposalUniqueLink) => {
      // Start the connection.
      var res = start()
        .then(() => {
          connection.invoke('Subscribe', proposalUniqueLink);
        })
        .catch(console.error);
      return res;
    };

    proposalHub.proposalClosed = async (proposalUniqueLink) => {
      if (connection.state === HubConnectionState.Connected) {
        await connection.invoke('Unsubscribe', proposalUniqueLink);
      }
      await connection.stop();
    };

    // Forward server side SignalR events through $proposalHub, where components will listen to them as events
    connection.on('AnswerChanged', (data) => {
      proposalHub.$emit('answer-changed', data);
    });
    connection.on('SubscribeList', (data) => {
      proposalHub.$emit('subscribe-list', data);
    });

    // register hub
    Vue.prototype.$proposalHub = proposalHub;
  },
};
