<template>
  <div>
    <ProposalStateTransitionEngine :proposalUniqueLink="proposalUniqueLink" ></ProposalStateTransitionEngine>
    <div v-if="!isLoadingOrError" >
      
      <AdminNavbar></AdminNavbar>
      <VCompletedProposal 
        :proposalUniqueLink='proposalUniqueLink' :isRestrictedPage="true"
        @reopen="onReopen">
      </VCompletedProposal>
    </div>
  </div>
</template>

<script>
import ProposalStateTransitionEngine from '@survey/fill/ui/ProposalStateTransitionEngine.vue';
import VCompletedProposal from '@survey/fill/ui/VCompletedProposal.vue';
import AdminNavbar from '@survey/navbar/AdminNavbar.vue';

import reopenProposalDialog from '@survey/fill/ui/reopenProposalDialog.js';
import { mapGetters } from 'vuex';

export default {
  components: {
    AdminNavbar,
    ProposalStateTransitionEngine,
    VCompletedProposal,
  },
  props: ['proposalUniqueLink'],
  computed: {
    ...mapGetters('proposalLoad', ['isLoadingOrError']),
  },
  methods: {
    onReopen() {
      const fn = reopenProposalDialog.bind(this);
      fn(this.proposalUniqueLink, true,`/restricted/proposal/${this.proposalUniqueLink}`);
    },
  }
};
</script>

<style scoped>

</style>
