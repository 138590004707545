<template>
  <div class="container page text-center">
    <i class="fa fa-check checkmark"></i>
      <h2 class="landing-title-small">YOU HAVE SUBMITTED THE {{ displayTitle }}</h2>
      <p class='text-exit pt-3'>{{ exitText }}</p>

      <div class="pt-5">
        <p>Need to change answers? Or accidentally completed the form?</p>
        <VEditButton class="eb-prim pt-3 pb-3 mt-2" @click="$emit('reopen')">Reopen and make changes</VEditButton>
        <v-dialog />
      </div>
      <!-- <div v-if="downloadLinkReady" style="margin-top: 80px">
        <a href="" v-on:click.prevent="onDownloadForm">Download A Copy</a>
      </div>
      <div v-else style="margin-top: 50px">
        <p>{{ prepareText }}</p>
      </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: { 
    proposalUniqueLink: String,
    isRestrictedPage: Boolean 
  },
  data() {
    return {
      downloadLinkReady: false,
      prepareText: 'Preparing a copy for you .',
    };
  },
  computed: {
    ...mapState('meta', ['exitText', 'displayTitle']),
  },
  mounted(){
    this.checkDownloadLinkRecursive();
  }, 
  methods: {
    checkDownloadLinkRecursive() {
      if (this.$debug) console.log('Checking if download ready');

      // this disables it when we navigate away from page (and other conditions). includes is Case sensitive
      if(!window.location.href.includes("ompleted") 
          || this.downloadLinkReady === true){
        return;
      }

      this.$api.getProposalApi(this.isRestrictedPage)
        .getIsDownloadlinkReady(this.proposalUniqueLink)
        .then((res) => {
          if (res.data === true) {
            this.downloadLinkReady = true;
          } else {
            setTimeout(
              function() {
                this.prepareText = this.prepareText === 'Preparing a copy .' ? 'Preparing a copy ...' : 'Preparing a copy .';
                this.checkDownloadLinkRecursive();
              }.bind(this),
              3000
            );
          }
        });
    },
    onDownloadForm() {
      this.$api.getProposalApi(this.isRestrictedPage).downloadProposal(this.proposalUniqueLink);
    },
  },
}
</script>

<style scoped>
.checkmark {
  font-size: 3rem;
  line-height: 1;
  color: #24b663;
}

.text-exit {
  white-space: pre-line;
}

@media only screen and (min-width: 40em) {
  .checkmark {
    font-size: 6rem;
  }
}
</style>