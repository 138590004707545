export function submitProposalDialog($modal, $toast, $store, submitCallBackFn, cancelCallBackFn){

  let completedPerc = $store.getters['fill/percCompleted'];

  let text = "This will submit the completed form to your broker. Are you sure?"
  if(completedPerc < 99){
    text = "<p>This will submit the completed form to your broker. Are you sure?<p>"
          + `<p class='text-center' style='border: 1px solid #d02547;'>You are <b>${completedPerc}</b>% complete</p>`
  }

  $modal.show('dialog', {
    title: 'Submit Application',
    text: text,
    buttons: [
      {
        title: 'Submit',
        handler: async () => {
          $modal.hide('dialog')

          $toast('Submiting. This may take a while. Please wait...', {
            timeout: 2000,
            icon: "fas fa-rocket",
            hideProgressBar: true,
          });
          // fire and forget submit request
          $store.dispatch('fill/SUBMIT');
          
          submitCallBackFn();
        }
      },
      {
        title: 'Back To Form',
        handler: () => {
          $modal.hide('dialog')
          cancelCallBackFn();
        }
      }
    ]
  });
}