import axios from '@shared/services/api/axiosConfig.js';

//const debug = process.env.NODE_ENV !== 'production';

export default {
  async featureDemo(){
    const res = await axios.get('/api/utils/feature/demo')
    return res;
  },

  async postSubmitFeedback(payload) {
    const res = await axios.post(`/api/mail/feedback/`, payload);
    return res;
  },
}
