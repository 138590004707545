<!-- file to be changed -->
<template>
  <nav class="sidebar sidebar-sticky" :class="sidebarActiveClass" :style="{background: sidebarColour}">
    <div class="sidebar-main">
      <VAutosaving 
        :autosaving="autosaving" 
        :offline="offline" 
        :proposalAccessList="proposalAccessList">
      </VAutosaving>

      <div style="height:calc(100vh - 40px)" class="attach-shepherd-sidebar">
        <Vuescroll>
          <div class="button-container mb-3 mt-1">
            <VEditButton @click="onFeedbackClick" style="width:200px">{{ feedbackBtnText}} </VEditButton>
          </div>          
          <div class="button-container mb-3 mt-1">
            <VEditButton @click="onRestrictAccess" style="width:200px"
              title="Restrict Access to this form to a configured set of email addresses or for everyone">
                {{restrictBtnText}}
            </VEditButton>
          </div>
          <div v-if="isFeedbackMode">
            <FeedbackMechanism @completeFeedback="onFeedbackComplete"></FeedbackMechanism>
          </div>
          <div v-else>
            <div class="sidebar-header">
              <h3>Table Of Contents</h3>
            </div>
            <ul class="list-unstyled components">
              <StepperSection
                v-for="(bookmarkSection, index) in bookmarks"
                :key="index"
                v-bind="bookmarkSection"
                :focusedIds="focusedIds"
              >
              </StepperSection>
            </ul>
          </div>
        </Vuescroll>
      </div>
    </div>
    <button class="btn btn-light sidebar-handle attach-shepherd-hide" @click="toggleSidebar">
      <i class="fas" :class="sidebarHandleIconClass"></i>
    </button>
    <v-dialog />
  </nav>
</template>


<script>
/**
 * This element's parent div must have .lay-nav-wrapper class
 *
 */

import Vuescroll from 'vuescroll';
import StepperSection from '@shared/components/stepper/ui/StepperSection.vue';
import VAutosaving from '@shared/components/stepper/ui/VAutosaving.vue';
import FeedbackMechanism from '@shared/components/stepper/ui/FeedbackMechanism.vue';

export default {
  components: {
    Vuescroll,
    StepperSection,
    VAutosaving,
    FeedbackMechanism
  },
  props: {
    bookmarks: {
      type: Array,
      default: () => []
    },
    focusedIds: {
      type: Array,
      default: () => []
    },
    active: {
      type: Boolean,
      default: () => false
    },
    autosaving: {
      type: Boolean,
      default: () => false
    },
    offline: {
      type: Boolean,
      default: () => false
    },
    proposalUniqueLink: {
      type: String,
      default: () => null
    },
    proposalAccessList: {
      type: Array,
      default: () => []
    },
    isRestricted: {
      type: Boolean,
      default: () => false
    }
  },
  data: function() {
    return {
      internalActive: false,
      isFeedbackMode: false
    };
  },
  computed: {
    sidebarActiveClass() {
      return this.internalActive ? 'active' : '';
    },
    bookmarksEnriched() {
      return this.bookmarks.map(x => Object.assign({ visible: false, focusedIds: this.focusedIds }, x));
    },
    sidebarHandleIconClass() {
      return this.internalActive ? 'fa-angle-double-right' : 'fa-angle-double-left';
    },
    sidebarColour() {
      let hexCode = this.$store.state.fill.meta.sidebarColour;
      return hexCode ? hexCode : '#214245';
    },
    feedbackBtnText(){
      return this.isFeedbackMode ?  'Close Feedback':'Leave Feedback'
    },
    restrictBtnText(){
      return this.isRestricted ?  'Unrestrict Access':'Restrict Access'
    }
  },
  methods: {
    init(value) {
      this.internalActive = value;
    },
    getToggleIdQuotes(str) {
      return `'${this.getToggleId(str)}'`;
    },
    toggleSidebar() {
      this.internalActive = !this.internalActive;
    },
    onFeedbackClick(){
      this.isFeedbackMode = !this.isFeedbackMode;
    },
    onFeedbackComplete(){
      this.isFeedbackMode = false;
    },
    onRestrictAccess(){
      this.$emit("restrictAccess");
    }
  },
  mounted() {
    this.init(this.active);
  },
  watch: {
    active: function(value) {
      this.init(value);
    }
  }
};
</script>

<style scoped>
.sidebar-sticky {
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  min-width: 328px;
  max-width: 328px;
}

.sidebar {
  color: #fff;
  transition: all 0.3s;
}

.sidebar.active {
  margin-left: -300px;
  min-width: 326px;
  max-width: 326px;
}

@media (max-width: 768px) {
  .sidebar {
    margin-left: -300px;
  }
  .sidebar.active {
    margin-left: 0;
  }
}

.sidebar-header {
  padding: 0.5rem;
}

.sidebar-main {
  width: 300px;
  height: 100vh;
  float: left;
}
.sidebar-handle {
  width: 20px;
  height: 100vh;
}

.sidebar.active .sidebar-handle {
  border-radius: 0px;
}

.fas {
  margin-left: -7px;
  margin-right: -0px;
}

.button-container
{
  display: flex;
  justify-content: center;
  margin-right: 10px;
}
</style>
