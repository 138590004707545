<template>
  <nav class="cool-bg-blue-alt navbar navbar-static-top navbar-expand-lg navbar-dark">
    <img src="/assets/image/BROKIT_Logo_v2_WHITE_16x9.png" alt="" width="117" height="40" />

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto"></ul>
      <div v-if="!$auth.loading">
        <span class="navbar-text mr-2" v-if="$auth.isAuthenticated">{{ $auth.user.name }}</span>
        <!-- show login when not authenticated -->
        <VEditButton v-if="!$auth.isAuthenticated" @click="login" class="eb-subtle">Sign in</VEditButton>
        <!-- show logout when authenticated -->
        <VEditButton v-if="$auth.isAuthenticated" @click="logout" class="eb-subtle">Log out</VEditButton>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$auth.logout();
    }
  }
};
</script>

<style scoped></style>
