import axios from '@shared/services/api/axiosConfig.js';

const debug = process.env.NODE_ENV !== 'production';

export default {
  async getPreFill(propUniqueLink){
    const res = await axios.get(`/api/external/proposal/${propUniqueLink}/prefill`)
    if (debug) console.log('getPreFill', res);
    return res;
  },
  async searchCharities(searchTerm, propUniqueLink) {
    if (debug) console.log('searchCharities', propUniqueLink);
    const res = await axios.get(`/api/external/proposal/${propUniqueLink}/prefillsearch/charity`, { params: { query: searchTerm} });
    return res;
  },
  async getCharity(propUniqueLink, regNo) {
    const res = await axios.get(`/api/external/proposal/${propUniqueLink}/prefill/CharitiesCommission/${regNo}`);
    return res;
  },
  async searchCompaniesHouse(searchTerm, propUniqueLink) {
    const res = await axios.get(`/api/external/proposal/${propUniqueLink}/prefillsearch/companieshouse`, { params: { query: searchTerm } });
    return res;
  },
  async getCompany(propUniqueLink, companyNumber) {
    const res = await axios.get(`/api/external/proposal/${propUniqueLink}/prefill/companieshouse/${companyNumber}`);
    return res;
  },
};
