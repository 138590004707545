import axios from '@shared/services/api/axiosConfig.js';
import { getAuthHeaders } from '@shared/services/auth/authApiServices.js';

const debug = process.env.NODE_ENV !== 'production';

export default {
  async getPreFill(propUniqueLink){
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/admin/external/proposal/${propUniqueLink}/prefill`, headers)
    if (debug) console.log('getPreFill', res);
    return res;
  },
  async searchCharities(searchTerm, propUniqueLink) {
    const headers = await getAuthHeaders();
    headers.params = { 'query': searchTerm};
    if (debug) console.log('searchCharities', propUniqueLink);
    const res = await axios.get(`/api/admin/external/proposal/${propUniqueLink}/prefillsearch/charity`, headers);
    return res;
  },
  async getCharity(propUniqueLink, regNo) {
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/admin/external/proposal/${propUniqueLink}/prefill/CharitiesCommission/${regNo}`, headers);
    return res;
  },
  async searchCompaniesHouse(searchTerm, propUniqueLink) {
    const headers = await getAuthHeaders();
    headers.params = { 'query': searchTerm};
    const res = await axios.get(`/api/admin/external/proposal/${propUniqueLink}/prefillsearch/companieshouse`, headers);
    return res;
  },
  async getCompany(propUniqueLink, companyNumber) {
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/admin/external/proposal/${propUniqueLink}/prefill/companieshouse/${companyNumber}`, headers);
    return res;
  },
};
