<template>
    <div v-if="isLoadingOrError" class="container">
    <PageLoading loadingText="Loading Form. Please wait..." v-if="isLoading"></PageLoading>
    <div v-else>
      <Page403Forbidden v-if="displayForbidden"></Page403Forbidden>
      <Page404 v-if="displayPage404"></Page404>
    </div>
  </div>
</template>

<script>
import Page404 from '@shared/components/coreUi/Page404.vue';
import Page403Forbidden from '@shared/components/coreUi/Page403Forbidden.vue';
import PageLoading from '@shared/components/coreUi/PageLoading.vue';

import { mapState, mapGetters } from 'vuex'
import { updatePageTitle } from '@shared/services/utils/pageTitleHelper.js';


export default {
  components: {
    Page404,
    PageLoading,
    Page403Forbidden
  },
  props: ['proposalUniqueLink'],
  data() {
    return {
      displayForbidden: false,
      displayPage404: false
    }
  },
  computed: {
    ...mapState('meta', ['displayTitle', 'proposalState', 'hasLandingPage']),
    ...mapState('proposalLoad', ['isLoading']),
    ...mapGetters('proposalLoad', ['isLoadingOrError', 'metaResponseStatus']),
    source() {
      if (this.$debug) console.log('this.$route', this.$route);

      if (this.$route.path.includes('landing')) {
        return 'clientLanding'
      } else if (this.$route.path.includes('form')) {
        return 'proposalFill'
      } else if (this.$route.path.includes('completed') && !this.$route.path.includes('restricted')) {
        return 'clientCompleted'
      } else if (this.$route.path.includes('/restricted/proposal/')) {
        return 'restrictedFill'
      } else if (this.$route.path.includes('/restricted/completed/')) {
        return 'restrictedCompleted'
      } else {
        throw 'Unexpected route'
      }
    },
    sourceIsRestricted() {
      return this.source === 'restrictedFill' || this.source === 'restrictedCompleted'
    }
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    fetchData() {
      if (this.$debug) console.log('FetchData');
      this.displayPage404 = false;
      this.displayForbidden = false;

      const payload = { proposalUniqueLink: this.proposalUniqueLink, isRestrictedPage: this.sourceIsRestricted };

      this.$store
        .dispatch('proposalLoad/LOAD_META', payload)
        .then(this.handleLoadSuccess)
        .catch(this.handleLoadError);
    },
    handleLoadError() {
      this.$store.commit('proposalLoad/loadingComplete');
    },
    handleLoadSuccess() {
      if (this.$debug) console.log('handleLoadSuccess', this.source);
      switch (this.source) {
        case 'clientLanding':
          this.stateClientLanding();
          break;
        case 'proposalFill':
          this.stateProposalFill();
          break;
        case 'clientCompleted':
          this.stateClientCompleted();
          break;
        case 'restrictedFill':
          this.stateRestrictedFill();
          break;
        case 'restrictedCompleted':
          this.stateRestrictedCompleted();
          break;
        default:
          break;
      }

      updatePageTitle(this.$store.getters.environmentMenuBar, this.displayTitle);
      this.$store.commit('proposalLoad/loadingComplete');
      this.$emit("loaded")
    },
    stateClientLanding() {
      if (this.metaResponseStatus === 200) {
        if (this.proposalState === 'Completed') {
          this.redirectCompleted();
        } else if (this.hasLandingPage === false) {
          this.redirectProposalFill();
        }
      } else if (this.metaResponseStatus === 403) {
        this.redirectRestricted();
      } else {
        this.displayPage404 = true;
      }
    },
    async stateProposalFill() {
      if (this.metaResponseStatus === 200) {
        if (this.proposalState === 'Completed') {
          this.redirectCompleted();
        } else {
          await this.makeFullRequests()
        }
      } else if (this.metaResponseStatus === 403) {
        this.redirectRestricted();
      } else {
        this.displayPage404 = true;
      }
    },
    stateClientCompleted() {
      if (this.metaResponseStatus === 200) {
        if (this.proposalState === 'Active') {
          this.redirectLandingPage();
        } else {
          // Do nothing
        }
      } else if (this.metaResponseStatus === 403) {
        this.redirectRestrictedCompleted();
      } else {
        this.displayPage404 = true;
      }
    },
    async stateRestrictedFill() {
      if (this.metaResponseStatus === 200) {
        if (this.proposalState === 'Completed') {
          this.redirectRestrictedCompleted();
        } else {
          await this.makeFullRequests()
        }
      } else if (this.metaResponseStatus === 403) {
        this.displayForbidden = true
      } else {
        this.displayPage404 = true;
      } 
    },
    stateRestrictedCompleted() {
      if (this.metaResponseStatus === 200) {
        if (this.proposalState === 'Active') {
          this.redirectRestricted();
        } else {
          // Do nothing
        }
      } else if (this.metaResponseStatus === 403) {
        this.displayForbidden = true
      } else {
        this.displayPage404 = true;
      } 
    },
    redirectRestricted() {
      if (this.$debug) console.log('redirectRestricted');

      if (this.source === 'restrictedFill' || this.source === 'restrictedCompleted') {
        // don't need to re-request as already got metadata from restricted api
        this.$store.commit('proposalLoad/skipMetaReq');
      }
      this.$router.push(`/restricted/proposal/${this.proposalUniqueLink}`);
    },
    redirectRestrictedCompleted() {
      if (this.$debug) console.log('redirectRestrictedCompleted');

      if (this.source === 'restrictedFill' || this.source === 'restrictedCompleted') {
        // don't need to re-request as already got metadata from restricted api
        this.$store.commit('proposalLoad/skipMetaReq');
      }
      this.$router.push(`/restricted/completed/${this.proposalUniqueLink}`);
    },
    redirectLandingPage() {
      if (this.$debug) console.log('redirectLandingPage');

      this.$store.commit('proposalLoad/skipMetaReq');
      this.$router.push(`/landing/${this.proposalUniqueLink}`);
    },
    redirectProposalFill() {
      if (this.$debug) console.log('redirectProposalFill');

      this.$store.commit('proposalLoad/skipMetaReq');
      this.$router.push(`/form/${this.proposalUniqueLink}`);
    },
    redirectCompleted() {
      if (this.$debug) console.log('redirectCompleted');

      this.$store.commit('proposalLoad/skipMetaReq');
      this.$router.push(`/completed/${this.proposalUniqueLink}`);
    },
    async makeFullRequests() {
      if (this.$debug) console.log('makeFullRequests');

      updatePageTitle(this.$store.getters.environmentMenuBar, this.displayTitle);
      const propPromise = this.$store.dispatch('fill/INIT', {
        proposalIdentifier: this.proposalUniqueLink,
        editable: true,
        isRestrictedPage: this.sourceIsRestricted
      });
      const commentsPromise = this.$store.dispatch('comments/INIT', {
        proposalIdentifier: this.proposalUniqueLink,
        user: 'Client',
        isRestrictedPage: this.sourceIsRestricted
      });
      const prefillPromise = this.$store.dispatch('preFill/INIT', {
        proposalIdentifier: this.proposalUniqueLink,
        isRestrictedPage: this.sourceIsRestricted
      });
      await Promise.all([propPromise, commentsPromise, prefillPromise]);
    },

  },
}
</script>

<style scoped>

</style>
