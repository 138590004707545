export function formTour(tour, id) {
  tour.addStep({
    title: 'Factfinder',
    text: 'Please complete this digital application form for your Broker.<br><br> <h6>This form automatically saves as you fill it in.</h6>',
    arrow: false,
    buttons: [
      {
        action() {
          localStorage.setItem('tourSkipped-'+id, true);
          return this.cancel();
        },
        classes: 'shepherd-button-secondary',
        text: 'Skip',
      },
      {
        action() {
          return this.next();
        },
        text: 'Next',
      },
    ],
  });
  tour.addStep({
    popperOptions: {
      modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
    },
    title: 'Navigation',
    text: 'The navigation panel allows for quick and easy navigation to elements of the form. Click on section titles to see their breakdown.',
    classes: 'shadow-md bg-purple-dark',
    attachTo: {
      element: '.attach-shepherd-sidebar',
      on: 'right',
    },
    buttons: [
      {
        action() {
          return this.back();
        },
        classes: 'shepherd-button-secondary',
        text: 'Back',
      },
      {
        action() {
          return this.next();
        },
        text: 'Next',
      },
    ],
  });
  tour.addStep({
    popperOptions: {
      modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
    },
    title: 'Hide Navigation Panel',
    text: 'The navigation panel can be easily hidden for easier viewing of the application form.',
    classes: 'shadow-md bg-purple-dark',
    attachTo: {
      element: '.attach-shepherd-hide',
      on: 'right',
    },
    buttons: [
      {
        action() {
          return this.back();
        },
        classes: 'shepherd-button-secondary',
        text: 'Back',
      },
      {
        action() {
          return this.next();
        },
        text: 'Next',
      },
    ],
  });
  tour.addStep({
    popperOptions: {
      modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
    },
    title: 'Comments',
    text: 'Comments can be left next to each question for your Broker to review.',
    classes: 'shadow-md bg-purple-dark',
    attachTo: {
      element: '.attach-shepherd-comment',
      on: 'left',
    },
    buttons: [
      {
        action() {
          return this.back();
        },
        classes: 'shepherd-button-secondary',
        text: 'Back',
      },
      {
        action() {
          document.getElementsByClassName('attach-shepherd-comment')[0].click();
          return this.next();
        },
        text: 'Next',
      },
    ],
  });
  tour.addStep({
    popperOptions: {
      modifiers: [{ name: 'offset', options: { offset: [-140, 340] } }],
    },
    title: 'Add Comment',
    arrow: false,
    text: 'Once you have written your comment it can be submitted. You can add as many as you need.',
    classes: 'shadow-md bg-purple-dark',
    attachTo: {
      element: '.attach-shepherd-comment',
      on: 'left',
    },
    buttons: [
      {
        action() {
          return this.back();
        },
        classes: 'shepherd-button-secondary',
        text: 'Back',
      },
      {
        action() {
          document.getElementsByClassName('attach-shepherd-comment')[0].click();
          window.scrollTo(0, document.body.scrollHeight);
          return this.next();
        },
        text: 'Next',
      },
    ],
  });
  tour.addStep({
    popperOptions: {
      modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
    },
    title: 'Submit',
    text: 'When you have completed your application form, it can be submitted to your Broker using the "Submit" button.',
    classes: 'shadow-md bg-purple-dark',
    attachTo: {
      element: '.attach-shepherd-submit',
      on: 'top',
    },
    buttons: [
      {
        action() {
          return this.back();
        },
        classes: 'shepherd-button-secondary',
        text: 'Back',
      },
      {
        action() {
          localStorage.setItem('tourSkipped-'+id, true);
          window.scrollTo(0, 0);
          return this.complete();
        },
        text: 'Done',
      },
    ],
  });
  tour.start();
}
