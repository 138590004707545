<template>
<div class="main-content">
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Open+Sans&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet" />

    <div class="container">
      <div class="row" style="padding-top: 50px; padding-bottom: 30px">
    <div class="row-bg-wrap instance-1">
      <div class="inner-wrap">
        <div
          class="row-bg"
          data-color_overlay=""
          data-color_overlay_2=""
          data-gradient_direction=""
          data-overlay_strength="0.3"
          data-enable_gradient="false"
        ></div>
      </div>
    </div>
    <div id="link-show" class="col" style="padding-bottom: 50px;">
      <p style="text-align: center; padding-top: 50px; padding-bottom: 30px">
        A secure unique link has been generated for your client to complete the factfind. 
        <br>Please email them the below link:
      </p>
      <p style="text-align: center; padding-top: 50px; padding-bottom: 30px">
        <a
          :href="link"
        >
          {{ link }}
        </a>
      </p>
      <p style="text-align: center">
        <a
          id="copyLink"
          class="nectar-button large accent-color regular-button"
          href="/elmoreIFrame.html?swiftproposal=9af2079a-e408-41b2-8a39-a7717520d8c7-2635-e467cb1d-c274-4b74-87af-a35672af430b"
          data-color-override="false"
          data-hover-color-override="false"
          data-hover-text-color-override="#fff"
          style="visibility: visible"
          ><span>Copy to clipboard</span></a
        >
      </p>
    </div>


      </div>
    </div>




  </div>
</template>

<script>
export default {
  props: {
    link: String
  }
};
</script>

<style scoped>
.main-content {
  background-color: #f8f8f8;
}

#page-header-bg h2,
body h2,
article.post .post-header h2,
article.post.quote .post-content h2,
article.post.link .post-content h2,
article.post.format-status .post-content h2,
#call-to-action span,
.woocommerce .full-width-tabs #reviews h3,
.row .col.section-title h2,
.nectar_single_testimonial[data-style='bold'] p {
  font-family: 'Montserrat';
  letter-spacing: 0.5px;
  font-size: 28px;
  line-height: 28px;
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #284546;
  letter-spacing: 0px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  font-family: 'Open Sans';
  font-weight: 600;
}

p {
  font-family: 'Open Sans';
  letter-spacing: 0.5px;
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
  color: #284546;
}

input[type='text'],
textarea,
input[type='email'],
input[type='password'],
input[type='tel'],
input[type='url'],
input[type='search'],
input[type='date'] {
  padding: 10px;
  width: 100%;
  font-size: 12px;
  font-family: inherit;
  line-height: 22px;
  color: #555;
  background-color: #efefef;
  border: none;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  box-sizing: border-box;
}

dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend {
  vertical-align: baseline;
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  outline: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.nectar-button {
  border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  -o-border-radius: 5px 5px 5px 5px;
  box-shadow: 0 -3px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 -3px rgba(0, 0, 0, 0.1) inset;
  -webkit-box-shadow: 0 -3px rgba(0, 0, 0, 0.1) inset;
  -o-box-shadow: 0 -3px rgba(0, 0, 0, 0.1) inset;
  transition: opacity 0.45s cubic-bezier(0.25, 1, 0.33, 1);
  -moz-transition: opacity 0.45s cubic-bezier(0.25, 1, 0.33, 1);
  -webkit-transition: opacity 0.45s cubic-bezier(0.25, 1, 0.33, 1);
  -o-transition: opacity 0.45s cubic-bezier(0.25, 1, 0.33, 1);
  color: #fff;
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 2px;
  padding: 13px 18px;
  position: relative;
  text-transform: uppercase;
  top: -3px;
  margin-bottom: 10px;
  opacity: 1;
  line-height: 20px;
  background-color: #284546 !important;
  display: inline-block;
  font-family: 'Montserrat';
}
.nectar-button.large {
  font-size: 14px;
  padding: 15px 22px;
  box-shadow: 0 -3px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 -3px rgba(0, 0, 0, 0.1) inset;
  -webkit-box-shadow: 0 -3px rgba(0, 0, 0, 0.1) inset;
  -o-box-shadow: 0 -3px rgba(0, 0, 0, 0.1) inset;
  width: 100%;
}

input:focus {
  border: none;
  border-color: transparent;

  outline: none;
}

.invalid-warning {
  background-color: rgb(255, 255, 255);
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
}
</style>

