<template>
  <li class='bookmark-question' :class='isFocused ? "focused" : null'>
    <a @click='onScrollTo'>
      {{nameShortened}}
      <span class='badge float-right' :class='badgeClass' v-if='this.comments>0'>{{comments}}</span>
    </a>
  </li>
</template>

<script>
import scrollMeTo from '@shared/components/stepper/utils/scrollMeTo.js';
const maxLengh = 70;

export default {
  props: {
    elemId: [Number, String],
    name: String,
    elem: String,
    comments: {
      type: Number,
      default: () => 0
    },
    read: {
      type: Boolean,
      default: () => false
    },
    focusedIds: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    badgeClass() {
      return this.read ? 'badge-light' : 'badge-danger';
    },
    nameShortened() {
      if(!this.name)
        return 'Question';

      return this.name.length > maxLengh ? this.name.substring(0, maxLengh - 3) + '...' : this.name;
    },
    isFocused() {
      return this.focusedIds.includes(this.elemId);
    },
  },
  methods: {
    onScrollTo() {
      this.$store.commit('fill/setFocus', this.elemId);
      scrollMeTo.to(this.elem);
    }
  }
};
</script>

<style scoped>
.bookmark-question {
  font-size: 0.9em !important;
  padding-left: 30px !important;
}

a[data-toggle='collapse'] {
  position: relative;
}

a,
a:hover,
a:focus {
  color: inherit !important;
  text-decoration: none !important;
  transition: all 0.3s;
}

li a {
  padding: 5px;
  display: block;
}

.bookmark-question:hover {
  color: #214245;
  background: #aed1e6;
  text-decoration: none !important;
}

.focused {
  color: #214245;
  background: #aed1e6;
}
</style>
