import Vue from 'vue';
import Vuex from 'vuex';
import formFill from '@shared/store/moduleProposalFill.js';
import comments from '@shared/store/moduleComments.js';
import features from '@shared/store/moduleFeatures.js';
import proposalMeta from '@shared/store/moduleProposalMeta.js';
import isVueStandalone from '@shared/services/api/environmentHelper.js';
import {isUatEnv} from '@shared/services/api/environmentHelper.js';
import preFill from '@shared/store/modulePreFill.js';
import proposalLoad from '@shared/store/moduleProposalLoad.js';


Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';
const vueIsStandalone = isVueStandalone();
const isUat = isUatEnv();

export default new Vuex.Store({
  strict: debug, // Vuex: Do not enable strict mode when deploying for production!
  modules: {
    fill: formFill,
    comments: comments,
    meta: proposalMeta,
    features: features,
    preFill: preFill,
    proposalLoad: proposalLoad,
  },
  getters: {
    displayNavBar: () => (routePath) => {
      return !(routePath.startsWith('/clients/') || routePath.startsWith('/puppet/'))
    },
    environmentMenuBar: () => {
      if(debug){
        if(vueIsStandalone){
          return "local-standalone"
        }else{
          return "local"
        }
      } else if(isUat === true){
        return "UAT";
      }

      return "";
    },
  }
});

