import axios from '@shared/services/api/axiosConfig.js';
import { apiPathStart, rootUrl } from '@survey/services/api/surveyApiHelper.js'

import extProposalApi from '@survey/services/api/extProposalApi.js'
import commentsApi from '@survey/services/api/extCommentsApi.js';
import extDemoApi from '@survey/services/api/extDemoApi.js';
import extUtilsApi from '@survey/services/api/extUtilsApi.js';
import extWholesaleApi from '@survey/services/api/extWholesaleApi.js';
import prefillApi from '@survey/services/api/prefillApi.js';
import adminPortalApi from '@survey/services/api/adminPortalApi.js';
import restrictedProposalApi from '@survey/services/api/restrictedProposalApi.js';
import restrictedCommentsApi from '@survey/services/api/restrictedCommentsApi.js';
import restrictedPrefillApi from '@survey/services/api/restrictedPrefillApi.js';

// Create an object that can be used. 
function createApi(){
  return {
    demo: extDemoApi,
    utils: extUtilsApi,
    wholesale: extWholesaleApi,
    apiPathStart: apiPathStart,
    rootUrl: rootUrl,
    adminPortal: adminPortalApi,
    getProposalApi: function(isRestrictedPage){
      return isRestrictedPage === true ? restrictedProposalApi : extProposalApi;
    },
    getCommentsApi: function(isRestrictedPage){
      return isRestrictedPage === true ? restrictedCommentsApi : commentsApi;
    },
    getPrefillApi: function(isRestrictedPage){
      return isRestrictedPage === true ?  restrictedPrefillApi : prefillApi;
    }
  };
}

export const $api = createApi();

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const ApisPlugin = {
  install(Vue) {
    Vue.prototype.$api = createApi();
    Vue.prototype.$http = axios;
  }
}
