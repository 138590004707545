<template>
  <div>
    <AdminNavbar></AdminNavbar>

    <div v-if="loadingState.metaLoading || loadingState.metaRespStatus !== 200" class="container">
      <PageLoading loadingText="Loading... Please wait..." v-if="loadingState.metaLoading"></PageLoading>
      <Page403Forbidden v-else-if="loadingState.metaRespStatus === 403"></Page403Forbidden>
      <Page404 v-else></Page404>
    </div>

    <div v-else class="container">
      <h1 class="text-center" style="padding-top: 1rem">{{ clientName }} - {{ proposalName }}</h1>
      <hr class="mb-5"/>
      <div v-if="willRestrict">
        <VRestrictCard :restrictedEmails="restrictedEmails" @ok="onRestrict" @cancel="onCancel"></VRestrictCard>
      </div>
      <div v-else>
        <VUnrestrictCard @ok="onUnrestrict" @cancel="onCancel"></VUnrestrictCard>
      </div>  
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import AdminNavbar from '@survey/navbar/AdminNavbar.vue';
import VRestrictCard from '@survey/clientAdmin/ui/VRestrictCard.vue';
import VUnrestrictCard from '@survey/clientAdmin/ui/VUnrestrictCard.vue';

export default {
  components: {
    AdminNavbar,
    VRestrictCard,
    VUnrestrictCard,
},
  data() {
    return {

    };
  },
  props: ['proposalUniqueLink'],
  computed: {
    ...mapState('meta', ['loadingState', 'restrictedEmails', 'proposalName', 'clientName', 'restricted']),
    willRestrict() {
      return !this.restricted;
    }
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData'
  },
  methods: {
    fetchData() {
      if (this.$debug) console.log('Loading form: ', this.proposalUniqueLink);
      this.$store.dispatch('meta/FETCH_META', { proposalUniqueLink: this.proposalUniqueLink, isRestrictedPage: true });
    },
    async onRestrict(){
      if (this.$debug) console.log('On Restrict Form');

      await this.changeProposalRestrictState(true);

      this.$router.push(`/landing/${this.proposalUniqueLink}`);
    },
    async onUnrestrict(){
      if (this.$debug) console.log('On Unrestrict Form');
      // restrict request
      await this.changeProposalRestrictState(false);

      this.$router.push(`/landing/${this.proposalUniqueLink}`);
    },
    async changeProposalRestrictState(newState) {
      await this.$api.adminPortal.postRestrictProposal(this.proposalUniqueLink, newState);
    },
    onCancel(){
      if (this.$debug) console.log('On Cancel');

      this.$router.push(`/landing/${this.proposalUniqueLink}`);
    }
  }
};
</script>

<style></style>
