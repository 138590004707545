// Libray imports
import Vue from 'vue';
import App from '@survey/App.vue';
import router from '@survey/router.js';
import store from '@survey/store/survey-store.js';
import BootstrapVue from 'bootstrap-vue';
import Toast from 'vue-toastification';
import VModal from 'vue-js-modal'
import VueSignaturePad from 'vue-signature-pad';
import VueShepherd from 'vue-shepherd';

// Auth0
import { domain, clientId, audience } from "@config/auth_survey_config.json";
import { Auth0Plugin } from '@shared/services/auth/auth.js';

// style sheets
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/css/all.css';
import "vue-toastification/dist/index.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "shepherd.js/dist/css/shepherd.css";

// My imports
import { ApisPlugin } from '@survey/services/api/apis.js';
import { UtilsApisPlugin } from '@shared/services/utils/utils.js';
import ProposalHub from '@survey/services/hubs/proposalHub.js';


// Register Global components
import '@shared/baseComponentReg.js'


// Register global libraries
Vue.use(Toast, { position: "bottom-center" });
Vue.use(BootstrapVue);
Vue.use(VModal, { dialog: true })
Vue.use(VueSignaturePad);
Vue.use(VueShepherd);

// Install the Auth0 authentication plugin
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

// setup $debug property
Vue.prototype.$debug = process.env.NODE_ENV !== 'production';

// Setup $api & $http
Vue.use(ApisPlugin)

// Proposal signalr hub
Vue.use(ProposalHub)

// setup $utils
Vue.use(UtilsApisPlugin)

Vue.config.productionTip = false;

// init store
store.dispatch('features/INIT_FEATURES');

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
