<template>
  <div>
    <ProposalStateTransitionEngine :proposalUniqueLink="proposalUniqueLink"></ProposalStateTransitionEngine>
    <div v-if="!isLoadingOrError" class="container page text-center">
      <img v-if="hasLandingImg" class="land-img" :src="landingImageSrc" alt="Landing Image" />
      <h1 class="landing-title-small">{{displayTitle}}</h1>
      <h2>{{clientName}}</h2>
      <p>
        {{landingText}}
      </p>
      <div class="pb-5 pt-3">
        <VEditButton class='eb-prim' @click="onStartForm">Continue</VEditButton>
      </div>
    </div>
  </div>
</template>

<script>
import ProposalStateTransitionEngine from '@survey/fill/ui/ProposalStateTransitionEngine.vue';

import { mapState , mapGetters} from 'vuex'

export default {
  components: {
    ProposalStateTransitionEngine
  },
  props: ['proposalUniqueLink'],
  computed:{
    ...mapState('meta', ['displayTitle', 'landingText', 'clientName', 'landingImage',]),
    ...mapGetters('proposalLoad', ['isLoadingOrError']),
    hasLandingImg(){
      return !this.$utils.str.isEmpty(this.landingImage)
    },
    landingImageSrc(){
      return this.$api.apiPathStart() + `/api/external/proposal/landingimage/${this.landingImage}`
    }
  },
  methods: {
    onStartForm(){
      this.$router.push(`/form/${this.proposalUniqueLink}`);
    }
  },
};
</script>

<style scoped>
.help-img {
  height:232px;
  width:210px;
}

.center-me {
  margin: 0 auto;
}

.land-img {
  object-fit: cover;
  height: 40vh;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.help-text {
  text-align: left
}
</style>
