import axios from '@shared/services/api/axiosConfig.js';
import { apiPathStart } from '@survey/services/api/surveyApiHelper.js'
import { getAuthHeaders } from '@shared/services/auth/authApiServices.js';
const debug = process.env.NODE_ENV !== 'production';

export default {
  // Unique to survey
  async downloadProposal(propUniqueLink) {
    const headers = await getAuthHeaders();
    headers.responseType = 'arraybuffer';
    const res = await axios.get(`/api/admin/external/proposal/${propUniqueLink}/pdf`, headers);

    let blob = new Blob([res.data], { type: 'application/*' });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'Application Form.pdf';
    link.click();
    URL.revokeObjectURL(link.href);
    
    return res;
  },
  async getProposalMeta(propUniqueLink, disableGlobalErrHandler) {
    const headers = await getAuthHeaders();
    if(debug) console.log(`getProposalMeta ${propUniqueLink}`)

    if(disableGlobalErrHandler === true)
      headers.disableGlobalErrHandler = true; // disable global error handler

    const res = await axios.get(`/api/admin/external/proposal/${propUniqueLink}/meta`, headers)
    return res;
  },
  async getIsDownloadlinkReady(propUniqueLink) {
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/admin/external/proposal/${propUniqueLink}/downloadlinkready`, headers)
    return res;
  },
  async postSubmitForm(propUniqueLink){
    const headers = await getAuthHeaders();
    const res = await axios.post(`/api/admin/external/proposal/${propUniqueLink}/submit`, null, headers);
    return res;
  },
  async postReopenForm(propUniqueLink){
    const headers = await getAuthHeaders();
    const res = await axios.post(`/api/admin/external/proposal/${propUniqueLink}/reopen`, null, headers);
    return res;
  },

  // Common to both controllers
  async getProposalFill(propUniqueLink){
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/admin/external/proposal/${propUniqueLink}`, headers)
    if (debug) console.log('getProposalFill', res);
    return res;
  },
  async postAnswer(propUniqueLink, payload){
    const headers = await getAuthHeaders();
    headers.disableGlobalErrHandler= true; // disable global error handler
    const res = await axios.post(`/api/admin/external/proposal/${propUniqueLink}/answer`, payload, headers)// disable global error handler
    return res;
  },
  async postRetryAnswers(propUniqueLink, payload){
    const headers = await getAuthHeaders();
    headers.disableGlobalErrHandler= true; // disable global error handler
    const res = await axios.post(`/api/admin/external/proposal/${propUniqueLink}/retryAnswers`, payload, headers)// disable global error handler
    return res;
  },
  async deleteSupportingDoc(propUniqueLink, supportingDocId){
    const headers = await getAuthHeaders();
    const res = await axios.delete(`/api/admin/external/proposal/${propUniqueLink}/deleteDoc/${supportingDocId}`, headers)
    return res;
  },

  urlSupportingDocUpload(propUniqueLink, questionId) {
    let initPath = `/api/admin/external/proposal/${propUniqueLink}/SupportingDoc/${questionId}`;
    let fullPath = apiPathStart() + initPath
    return fullPath;
  },
  urlSupportingDocDownload(propUniqueLink, supportingDocId) {
    let initPath = `/api/admin/external/proposal/${propUniqueLink}/downloadDoc/${supportingDocId}`;
    let fullPath = apiPathStart() + initPath
    return fullPath;
  },
}
