<template>
  <div class="lay-nav-wrapper">
    <QuestionsStepperSidebar
      :bookmarks="tableOfContents"
      :focusedIds="focus"
      :active="!formExists"
      :autosaving="saving"
      :offline="offline"
      :proposalUniqueLink="proposalUniqueLink"
      :proposalAccessList="proposalAccessList"
      :isRestricted="restricted"
      @restrictAccess="onRestrictAccess"
    ></QuestionsStepperSidebar>
    <ProposalStateTransitionEngine :proposalUniqueLink="proposalUniqueLink" @loaded="onLoaded"></ProposalStateTransitionEngine>
    <div v-if="!isLoadingOrError" class="container">
      <h1 class="mt-2">{{ displayTitle }}</h1>
      <div>
        <div v-for="(content, index) in filteredSections" :key="content.id">
          <QuestionSection v-bind="content" :index="index" :totalSections="filteredSections.length"></QuestionSection>
          <hr />
        </div>
      </div>
      <div class="lay-options-both mt-5 mb-5">
        <VEditButton class="eb-success btn-block attach-shepherd-submit" @click="onSubmit" :disabled="!submitButtonEnabled"
          >Submit</VEditButton
        >
      </div>
    </div>
    <v-dialog />
    <VFormOffline />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import ProposalStateTransitionEngine from '@survey/fill/ui/ProposalStateTransitionEngine.vue';
import QuestionSection from '@shared/components/questions/ui/QuestionSection.vue';
import QuestionsStepperSidebar from '@shared/components/stepper/QuestionsStepperSidebar.vue';
import { submitProposalDialog } from '@survey/fill/ui/submitProposalDialog.js';
import VFormOffline from '@shared/components/formFilling/VFormOffline.vue';
import { formTour } from '@survey/fill/services/shepherdTour.js';

export default {
  components: {
    QuestionSection,
    QuestionsStepperSidebar,
    VFormOffline,
    ProposalStateTransitionEngine,
  },
  name: 'ClientProposalFillPage',
  data() {
    return {
      submitButtonEnabled: true,
      internalIsLoading: true,
      proposalAccessList: [],
    };
  },
  props: ['proposalUniqueLink'],
  computed: {
    ...mapState('fill', ['focus', 'tableOfContents', 'formExists', 'saving', 'offline']),
    ...mapState('meta', ['displayTitle', 'restricted', 'surveyHelp']),
    ...mapGetters('fill', ['filteredSections']),
    ...mapGetters('proposalLoad', ['isLoadingOrError']),
  },
  beforeDestroy() {
    this.unregisterForAnswerUpdates();
  },
  methods: {
    async onLoaded() {
      if(this.shouldDoTour()){
        this.$nextTick(() => {
          const tour = this.$shepherd(shepherdTourOptions());
          formTour(tour, this.proposalUniqueLink);
        });
      }
      this.registerForAnswerUpdates();
    },
    registerForAnswerUpdates() {
      this.$proposalHub.proposalOpened(this.proposalUniqueLink);
      this.$proposalHub.$on('answer-changed', this.onAnswerChanged);
      this.$proposalHub.$on('subscribe-list', this.onSubscribeListChange);
    },
    unregisterForAnswerUpdates() {
      if (this.$debug) console.log('Unsubscribe Signalr');
      this.$proposalHub.$off('answer-changed', this.onAnswerChanged);
      this.$proposalHub.$off('subscribe-list', this.onSubscribeListChange);
      this.$proposalHub.proposalClosed(this.proposalUniqueLink);
    },
    onSubmit() {
      this.submitButtonEnabled = false;

      const vue = this;
      const onSubmitFn = () => { // After 2 seconds redirect to completed page
        setTimeout(function () { 
          vue.$router.push(`/completed/${vue.proposalUniqueLink}`);
        }, 2000)
      };
      const onCancelFn = () => {              
        vue.submitButtonEnabled = true;
      }

      submitProposalDialog(this.$modal, this.$toast, this.$store, onSubmitFn, onCancelFn);
    },
    onAnswerChanged(data) {
      if (this.$debug) console.log('onAnswerChanged', data);
      this.$store.commit('fill/setAnswer', { id: data.id, value: data.value });
    },
    onSubscribeListChange(data) {
      if (this.$debug) console.log('onSubscribeListChange', data);

      this.proposalAccessList = data.ipAddresses;
    },
    onRestrictAccess(){
      this.$router.push(`/restrict-access/${this.proposalUniqueLink}`);
    },
    shouldDoTour(){
      let skipTour = localStorage.getItem('tourSkipped-' + this.proposalUniqueLink);
      return skipTour !== 'true' && this.$route.query.skip !== 'true' && this.surveyHelp === true;
    }
  },
};

function shepherdTourOptions(){
  return {
    useModalOverlay: false, // dIsable as causes issues on some mobiles
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
      classes: '',
      scrollTo: { behavior: 'smooth', block: 'center' },
    },
  };
}

</script>

<style scoped>
.eb-large {
  width: 150px;
}
</style>
