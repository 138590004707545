import axios from '@shared/services/api/axiosConfig.js';
import { getAuthHeaders } from '@shared/services/auth/authApiServices.js';
const debug = process.env.NODE_ENV !== 'production';

export default {
  async getProposalComments(propUniqueLink){
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/admin/external/proposal/${propUniqueLink}/comments`, headers)
    if (debug) console.log('getProposalComments', res);
    return res;
  },
  async postComment(propUniqueLink, payload){
    const headers = await getAuthHeaders();
    const res = await axios.post(`/api/admin/external/proposal/${propUniqueLink}/comment`, payload, headers)
    return res;
  },
  async deleteComment(propUniqueLink, commentId){
    const headers = await getAuthHeaders();
    const res = await axios.delete(`/api/admin/external/proposal/${propUniqueLink}/comment/${commentId}`, headers);
    return res;
  },
}
