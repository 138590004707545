import axios from '@shared/services/api/axiosConfig.js';
import { getAuthHeaders } from '@shared/services/auth/authApiServices.js';

const debug = process.env.NODE_ENV !== 'production';

export default {
  async postRestrictProposal(proposalUniqueLink, isToBeRestricted) {
    if (debug) console.log('postRestrictForm');
    const headers = await getAuthHeaders();

    let url = `/api/elevateduser/${proposalUniqueLink}/unrestrict`
    if(isToBeRestricted){
      url = `/api/elevateduser/${proposalUniqueLink}/restrict`
    }

    const res = await axios.post(url, null, headers);
    return res;
  }
};
