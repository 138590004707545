<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">You will restrict access to the factfinder</h5>
      </div>
      <div class="modal-body">
        <p>The form can only be accessed by logging in using a code. The code will be sent via email during the login flow (Passwordless Login).</p>
        <p>The following list of email addresses will be able to access the form:</p>
        <li v-for="email in restrictedEmails" v-bind:key="email">
          {{ email }}
        </li>
        <p class="mt-3">Please contact your broker if you would like to add more emails to the list.</p>
      </div>
      <div class="mt-3 lay-card-bottom modal-footer">
        <VEditButton class="b-100 eb-sec" @click="$emit('cancel')">Cancel</VEditButton>
        <VEditButton class="b-100 eb-prim"  @click="$emit('ok')">Ok</VEditButton>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      restrictedEmails: {
        type: Array,
        default: () => []
      },
    },
  }
</script>

<style scoped>

</style>